import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as VueGoogleMaps from 'vue2-google-maps'



Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjgRvaJJnWL2w_aYaJk57xG8sSci6aj7I',
    libraries: 'places',
    region: 'EE',
    language: 'EE'
  }
});

Vue.use(VueLoading)
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

