<template>
<div class="wrapper">
          <button class="btn btn--minus" @click="removeNumber()" type="button" name="button">
          -
          </button>
          <input class="quantity" type="text" name="name" :value="amount" readonly>
          <button class="btn btn--plus" @click="addNumber()" type="button" name="button">
            +
          </button>
        </div>
</template>

<script>
export default {
    name: 'PlusMinusBtn',
    props: {
        amount: Number,
    },
    methods: {
        addNumber() {
            if (this.amount != 25) {this.$emit("changeAmount", 1)}
        },
        removeNumber() {
            if (this.amount != 1) {this.$emit("changeAmount", -1)}
        }
    }
}
</script>

<style scoped>

.wrapper {
	 height: 50px;
	display: flex;
    color: var(--primary-color);
    overflow: hidden;
    background: var(--semi-primary-color);
}
.quantity {
  -webkit-appearance: none;
  border: none;
  text-align: center;
    width: 50px;
     color: var(--primary-color);
  font-size: 18px;
  font-weight: 700;
}

.btn {
  width: 30px;
/*   border-radius: 6px; */
  cursor: pointer;
  font-size: 30px;
  margin: 0 10px ;
}
button:focus,
input:focus {
  outline:0;
}

@media (max-width: 400px) {

  .quantity {
  width:35px!important;
}
}
</style>