import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      defaultTheme: 'dark',
      themes: {
        light: {
          primary: "#c39d63", // #E53935
          background: "#E53935",
        },
        dark: {
          primary: "#c39d63"
        }
      },
    },
  })
