<template>
    <div id="categories" v-if="cats.length != 0">
        <!-- 
        <v-tabs
        id="tabs"
        fixed-tabs
        slider-size="2"
        slider-color="black"
        color="black"

        @change="tabChange"
        v-model="tab"
        >
        <v-tab id="tab" v-for="cat in cats" :key="cat">{{cat}}</v-tab>
        </v-tabs>
        -->
        <div id="select">
        <v-select
          :items="cats"
          label="Kategooria"
          @change="tabChange"
          :value="cats[tab]"
          height="50px"
          dense
          outlined
        ></v-select>
        </div>

    </div>
</template>

<script>
export default {
    name:"categories",
    props: {
        cats: Array,
        tab: Number
    },
    methods: {
        tabChange(e) {
            let tab = this.cats.indexOf(e)
            console.log(tab)
            this.$emit('tabChange', tab)
        }
    }
}
</script>
<style scoped>
#select {
    width: 95%;
    margin: 0;
}
@media (min-width: 660px) {
    #categories {
        display: none;
    }
}
@media (max-width: 660px) {
    #categories {
        display: flex;
        justify-content: center;
    }
}
#tabs {
    max-width: var(--products-width);
}


</style>