<template>
    <div class="btn-wrapper">
        <div id="btn" v-if="getTotal() != 0" @click="openCart()">
            <div class="left">
            <div id="count">
                {{getCartTotalCount()}}
            </div>
            <h3 id="text">Ostukorv</h3>
        </div>
            <span id="price"><p>{{getTotal().toFixed(2)}}€</p></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CheckoutBtn',
    props: {
        getTotal: Function,
        getCartTotalCount: Function,

    },
    methods: {
        openCart() {
            this.$emit("openCart", true)
        },
    }
}
</script>

<style scoped>
.btn-wrapper {
    width: 100%;
    position: fixed;
    top: 20px;
    right: 20px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 2;

}
#btn {
    display: flex;
    align-items: center;
    width: 200px;
    height: 60px;
    background: var(--primary-color);
    border-radius: 10px;
    display: flex;
    cursor: pointer;
}


#count {
    margin: 10px 10px;
    background: white;
    width: 22px;
    height: 22px;
    color: var(--primary-color);
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
}
#price {
    margin-top: 15px;
    position: absolute;
    right: 20px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
}
#text {
    line-height: 40px;
    height: 40px;
    font-size: 17px;
    color: white;
    font-weight: 500;
}
.left {
    display: flex;
}
@media (max-width: 1280px) {
    .btn-wrapper {
        top:auto;
        right: auto;
        bottom: 20px!important;
        height: 40px;
        width: 100%;
    }
    #btn {
        width: 90%!important;
        margin: 0 auto;
        bottom: 20px;
        height: 40px;
        display: flex;
        justify-content: space-between;
    }
    #price {

    font-size: 15px;
    position: relative;

}

    #text {

    }
}
</style>