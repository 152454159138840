<template>
  <div id="list">
    <v-tabs-items v-model="tab" touchless >
      <v-tab-item v-for="category in Object.keys(products)" :key="category" >

        <v-list
          color="hsl(36, 44%, 97%)"
          subheader
       
          max-width="var(--products-width)"
          id="list"
        >
          <v-subheader id="category">{{ category }}</v-subheader>
          <v-divider></v-divider>

          <div v-for="product in products[category]" :key="product.id">
            <v-list-item
              id="list-item"
              :style="[
                getCartCount(product.id)
                  ? { 'border-left': '4px solid var(--primary-color)' }
                  : { 'border-left': '0px solid' },
              ]"
              @click="addCartDialog(product.id, product.stocked)"
            >
              <v-list-item-content>
                <v-list-item-title id="title" class="text-wrap"
                  ><span id="count" v-if="getCartCount(product.id)"
                    >{{ getCartCount(product.id) }}x </span
                  >{{ product.name }}</v-list-item-title
                >
   
                <v-list-item-subtitle class="text-wrap" id="subtitle">{{
                  product.description
                }}</v-list-item-subtitle>
       
                <p id="price">{{ product.price.toFixed(2) }}€</p>
                <div v-if="product.stocked || product['diets'].length != 0">

                  <v-chip
                    v-if="product.stocked == false"
                    color="var(--danger-color)"
                    id="chip"
                  >
                    <v-icon left> mdi-alert-circle-outline </v-icon>Väljamüüdud
                  </v-chip>


                  <v-chip
                    small
                    color="var(--primary-color)"
                    id="chip"
                    v-for="diet in product['diets']"
                    :key="diet"
                  >
                    <v-icon left small> {{getIcon(diet)}} </v-icon>{{ diet }}
                  </v-chip>
   
                </div>
 
              </v-list-item-content>
              <v-list-item-avatar
                class="rounded"
                size="30%"
                max-width="190px"
                min-width="145px"
              >
                <v-img :src="product.image"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  props: {
    products: Object,
    tab: Number,
    cart: Object,
  },
  methods: {
    getIcon(diet) {
      switch (diet) {
        case "Laktoosivaba":
          return ""
        case "Pähklivaba":
          return "mdi-peanut-outline"
        case "Ilma munata":
          return "mdi-egg-outline"
        case "Vegan":
          return "mdi-sprout-outline"
        case "Gluteenivaba":
          return ""
      }
    },
    getCartCount(id) {
      if (id in this.cart) {
        let count = 0;
        for (let i = 0; i < this.cart[id].length; i++) {
          count += this.cart[id][i].amount;
        }

        return count;
      } else {
        return 0;
      }
    },
    addCartDialog(id, stocked) {
      if (stocked == true){
      this.$emit("addCartDialog", id);
      }
    },
  },
};
</script>

<style scoped>

#chip {
  margin-right: 5px;
  margin-top: 5px;
  color: white;
  font-weight: bold;
}
#price {
  margin: 10px 0;
  color: var(--primary-color);
}
#chip {
  width: auto;
}
#list-item {
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

#count {
  color: var(--primary-color);
}
#title {
  overflow-wrap: break-word;
  font-weight: 500;
}
#subtitle {
  white-space: normal;
  font-weight: 400;
  overflow-wrap: break-word;
  word-break: break-word;
}
#chips {
  display: flex;
}

#category {
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0;
  margin-top:0;
  color: rgb(32, 33, 37);
}
@media (min-width: 340px) {
  #title {
    font-size: 21px;
  }
  #subtitle {
    font-size: 17px;
  }
}

@media (max-width: 340px) {
  #title {
    font-size: 15px;
  }
  #subtitle {
    font-size: 11px;
  }
}
@media (min-width: 800px) {
  #list {
    min-width: var(--products-width);
  }
}

</style>