<template>
    <div id="window" v-if="getCartTotalCount() != 0">

        <v-list
            subheader
            three-line
            max-width="var(--products-width)"
            id="list"
          >
            <v-subheader id="sideTitle">Ostukorv</v-subheader>
    

            <div v-for="product in Object.keys(cart)" :key="product">
              <div v-for="(cartItem, index) in cart[product]" :key="index">
                <v-list-item id="list-item">
                  <v-list-item-content>
 <div id="titleWrap">
                      <div id="t-l">
                      <v-list-item-title id="title" class="text-wrap"
                        ><span id="count">{{ cartItem.amount }}x </span
                        >{{ getProductData(product).name }}<span id="productOptionTitle" v-if="cartItem.option"> - {{cartItem.option}}</span>
                        </v-list-item-title>
                  </div>
                    <div id="t-r">
                      <div id="sideWrap">
                    <p id="price">{{ getCartItemTotal(cartItem, product) }}€</p>
                         <v-btn icon @click="removeCart(product,index)" id="iconBtn">
                            <v-icon id="dicon">
                              mdi-close
                            </v-icon>
                        </v-btn>
                        </div>
                    </div>
                    </div>
                    <p
                      id="extras"
                      v-for="extra in cartItem.extras"
                      :key="extra"
                    >
                      {{ extra }} ({{ getProductData(product).extras[extra] }}€)
                    </p>
                    <p id="note">{{ cartItem.note }}</p>
                  </v-list-item-content>

                </v-list-item>
                <v-divider></v-divider>
              </div>
            </div>
          </v-list>
          <v-btn color="var(--primary-color)" id="openCart" @click="changeCartDialog(true)">
            Tellima
          </v-btn>
    </div>
</template>

<script>
export default {
    name: 'LargeCart',
    props: {
        cart: Object,
        getProductData: Function,
        note: String,
        getTotal: Function,
        getCartTotalCount: Function,
  },
  methods: {
    changeCartDialog(bool) {
        this.$emit("changeCartDialog",bool)
    },
        removeCart(id, index) {
      this.$emit("removeCart", id, index)
    },
        getCartItemTotal(currentCartItem, productId) {
      let totalPrice = 0;
      let currentTotalPrice = 0.0;
      let currentProduct = this.getProductData(productId);

      if (currentCartItem.option == Object.keys(currentProduct.options)[0]) {
        currentTotalPrice += currentProduct.price;
      } else {
        currentTotalPrice += currentProduct.options[currentCartItem.option];
      }
      for (let ie = 0; ie < currentCartItem.extras.length; ie++) {
        currentTotalPrice += currentProduct.extras[currentCartItem.extras[ie]];
      }

      currentTotalPrice = currentTotalPrice * currentCartItem.amount;
      totalPrice += currentTotalPrice;
      return Math.round(totalPrice * 100) / 100;
    },
    getExtras(cartItem) {
      let extras = "";
      for (let i = 0; i < cartItem.extras.length; i++) {
        extras += cartItem.extras[i];
        if (i < cartItem.extras.length - 1) {
          extras += ", ";
        }
      }
      return extras;
    },
  }
}
</script>

<style scoped>
#productOptionTitle {
  font-weight: 400;
}
#openCart {
    color: white;
    text-transform: none;
    font-size: 22;
    font-weight: 500;
    width: 100%;
}
/* Change to make work CHANGE */
@media (max-width: 11450px) {
    #window {
        display: none;
    }
}
#window {
    width: 400px;
    margin-left: 26%;
    margin-top: 40px;
}
#dicon {
  color: var(--danger-color);
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 5px;
}
#price {

margin: 0;
margin-bottom: 5px;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.9;
}
#note {
  color: rgba(0, 0, 0, 0.7);
  margin-left: var(--extra-margin);
  font-weight: 500;
  margin-top: -4px;
}
#extras {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-left: var(--extra-margin);
}
#sideTitle {
      font-size: 22px;
  font-weight: 600;
  color: rgb(32, 33, 37, 1);
  margin-left: -10px;
}
#title {
  font-weight: 500;
}
#productOptionTitle {
  font-size: 16px;
}
#t-l {
  float: left;
  width: 70%;
}
#t-r {
  float: right;
  width: 29%;
  margin-top: -4px;
  
}
#sideWrap {
  display: flex;
  float:right;

}
#titleWrap {
  margin-bottom: -2px;
}
</style>