<template>
     <v-btn

      icon
      id="close-btn"
      @click="click()"
      x-large
    >
      <v-icon dark>
        mdi-close
      </v-icon>
    </v-btn>
</template>


<script>
export default {
    name: 'CloseBtn',
    methods: {
        click() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>


#close-btn {
    position: fixed;
    z-index: 9999;
    margin-top: 25px;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    background: white;
}
</style>