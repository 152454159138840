<template>
  <div id="categories" class="tabs">

    <v-tabs
      id="tabs"
      vertical
      slider-size="2"
      color="black"
      @change="tabChange"
      :value="tab"
    >
<!--       <div
        @click="openCart"
        id="cart"
        :style="[
          getCartTotalCount() != 0
            ? { visibility: 'visible' }
            : { visibility: 'hidden' },
        ]"
      >
        <div id="count">
          {{ getCartTotalCount() }}
        </div>
        <h3 id="text">Ostukorv</h3>
      </div> -->
      <v-tab id="tab" v-for="cat in cats" :key="cat">{{ cat }}</v-tab>
    </v-tabs>

  </div>
</template>
<script>
export default {
  name: "SideCategories",
  props: {
    cats: Array,
    tab: Number,
    getCartTotalCount: Function,
  },
  methods: {
    tabChange(e) {
      this.$emit("tabChange", e);
    },
    openCart() {
      this.$emit("openCart", true);
    },
  },
};
</script>

<style scoped>

#cart {
  display: flex;
  background: var(--primary-color);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
}
#count {
  margin: 7.5px 10px;
  background: white;
  width: 22px;
  height: 22px;
  color: var(--primary-color);
  text-align: center;
  border-radius: 50px;
  font-size: 14px;
}
#price {
  margin-top: 9px;
  position: absolute;
  right: 20px;
  color: white;
  font-size: 15px;
  font-weight: 400;
}
#text {
  line-height: 40px;
  font-size: 17px;
  color: white;
  font-weight: 500;
}
#categories {
  border-radius: 1px;
}
@media (min-width: 660px) {
  #categories {
    width: 190px;
    margin-top: 30px;
    margin-right: 8%;
    

  }
}

@media (max-width: 660px) {
  #categories {
    display: none;
  }
}

.card-wrapper {
  border-radius: 10px!important;
  background: white;
}
.v-tab {
  text-transform: none;
}
#tab {
  font-size: 15px;
  height: 35px;
  border-radius: 15px;
}
</style>
<style> 

.theme--light.v-tabs > .v-tabs-bar {
  background: var(--v-background-base)!important;
}

</style>