<template>
  <GmapAutocomplete
    :value="currentPlace.formatted_address"
    id="mapComplete"
    :componentRestrictions="{ country: 'EE' }"
    :selectFirstOnEnter="true"
    @place_changed="setPlace"

    placeholder="Address, maja, korter"
  />
</template>

<script>
export default {
  name: "ChooseAddress",
  props: {
    center: Object,
    currentPlace: Object,
  },
  methods: {
    setPlace(e) {
      this.$emit("setPlace", e);
    },
  },
};
</script>

<style scoped>
#label {
  color: #000000b3;
  font-weight: 600;
  margin-left: 5%;
}
#map {
  margin-top: 20px;
  width: 90%;
  height: 150px;
  margin: 0 auto;
}
#mapComplete {
  height: 40px;
  width: 100%;
  border: 1px solid rgb(177, 177, 177);
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
}

#mapComplete:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}
</style>